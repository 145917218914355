import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from './components/AppContainer/AppContainer'
import storeCarouselOnly from './store/storeCarouselOnly'
import { CarouselContainer } from './components/CarouselContainer/CarouselContainer'
import { Instances } from './components/Instances/Instances'
import { css, ServerStyleSheet } from 'styled-components'
import { renderToString } from 'react-dom/server'
import { SSRContainer } from './components/_ssr/SSRContainer/SSRContainer'
import { Header } from './components/_header/Header/Header'

const AppJSX = () => <AppContainer
  store={storeCarouselOnly}
  showErrorBoundary={false}
>
  <Header />
  <Instances/>
</AppContainer>

if (typeof window.location !== 'undefined') {
  ReactDOM.hydrate(
    <AppJSX />
    , document.getElementById('root')
  )
} else {
  window.render = function render() {
    const sheet = new ServerStyleSheet()
    const html = renderToString(
      <SSRContainer sheet={sheet}>
        <AppJSX />
      </SSRContainer>
    )
    const styleTags = sheet.getStyleTags()
    sheet.seal()
    return {
      html,
      styleTags
    }
  }
}

if (document.getElementById('reactCarousel')) {
  ReactDOM.render(
    <AppContainer
      store={storeCarouselOnly}
    >
      <CarouselContainer
        gqlType='homepage'
        index={0}
        containerDivStyles={css`
          width: 100%;
          @media (min-width: 1200px) {
            padding: 0 9.5rem;
          }
        `}
        parentDivStyles={css`
          width: 100%;
          margin-bottom: 0;
        `}
        carouselContainerStyles={css`
          justify-content: space-evenly;
          margin-bottom: 3rem; 
          width: 100%;
        `}
      />
    </AppContainer>, document.getElementById('reactCarousel')
  )
}

if (document.getElementById('reactCarousel2')) {
  ReactDOM.render(
    <AppContainer
      store={storeCarouselOnly}
    >
      <CarouselContainer
        gqlType='homepage'
        index={1}
        containerDivStyles={css`
          width: 100%;
          @media (min-width: 1200px) {
            padding: 0 9.5rem;
          }
        `}
        parentDivStyles={css`
          width: 100%;
          margin-bottom: 0;
        `}
        carouselContainerStyles={css`
          justify-content: space-evenly;
          margin-bottom: 3rem; 
          width: 100%;
        `}
      />
    </AppContainer>, document.getElementById('reactCarousel2')
  )
}
